import React from "react";
import { ReadLinkExternal } from "../components/read-link";

import Layout from "../components/layout";

export default () => (
  <Layout>
    <div>
      <ul>
        <li>
          <ReadLinkExternal
            target="_blank"
            rel="noopener noreferrer"
            href="//www.youtube.com/watch?v=8kzu9Mnk9XE&t"
          >
            Visualising Front-End Performance Bottlenecks [React Summit 2020]
          </ReadLinkExternal>
        </li>
      </ul>
    </div>
  </Layout>
);
